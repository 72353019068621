// $(document).ready(function() {
//     $('#validators-table').DataTable( {
//         autoWidth: false,
//         columnDefs: [
//             {
//                 targets: ['_all'],
//                 className: 'mdc-data-table__cell'
//             }
//         ]
//     } );
// } );


// $(document).ready(function() {
//     $('#example').DataTable();
// } ); 

// var triggerTabList = [].slice.call(document.querySelectorAll('#myTab button'))
// triggerTabList.forEach(function (triggerEl) {
//   var tabTrigger = new bootstrap.Tab(triggerEl)

//   triggerEl.addEventListener('click', function (event) {
//     event.preventDefault()
//     tabTrigger.show()
//   })
// })